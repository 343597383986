<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
// window.bootstrap = require('bootstrap');

import '@/scss/app.scss'
// import './assets/bootstrap/css/bootstrap.min.css'
import './assets/fonts/line-awesome.min.css'
import './assets/css/styles.css'
import './assets/css/custom.css'
// import './assets/js/jquery.min.js'
import './assets/bootstrap/js/bootstrap.js'
// import './assets/bootstrap/js/bootstrap.bundle.min.js'

import '../node_modules/nprogress/nprogress.css'

export default {
  computed: {
    layout() {
      return `layout-${this.$route.meta.layout || 'default'}`
    }
  }
}
</script>
