<template>
	<div class="loader" v-if="loading"></div>
	<div aria-live="polite" aria-atomic="true" class="position-relative">
		<div class="toast-container top-0 end-0 p-3">
			<div id="toastError" class="toast align-items-center text-bg-danger border-0" role="alert" aria-live="assertive"
				aria-atomic="true">
				<div class="d-flex">
					<div class="toast-body">
						{{ toast.body }}
					</div>
					<button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
						aria-label="Close"></button>
				</div>
			</div>
		</div>
	</div>
	<div aria-live="polite" aria-atomic="true" class="position-relative">
		<div class="toast-container top-0 end-0 p-3">
			<div id="toastSuccess" class="toast align-items-center text-bg-success border-0" role="alert"
				aria-live="assertive" aria-atomic="true">
				<div class="d-flex">
					<div class="toast-body">
						{{ toast.body }}
					</div>
					<button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
						aria-label="Close"></button>
				</div>
			</div>
		</div>
	</div>
	<div>
		<nav class="navbar navbar-light navbar-expand-md mb-0" v-if="!['/login', '/register'].includes($route.path)">
			<div class="container-fluid px-3 px-md-4">
				<a class="navbar-brand" href="#"><img src="assets/img/logo-img.png" /><span>DEGREE CRYPTO<br />TOKEN</span></a>
				<button data-bs-toggle="collapse" class="navbar-toggler"  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="visually-hidden">Toggle navigation</span>
						<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
							<path d="M4 6H20M4 12H12M4 18H20" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
						</svg>
				</button>
				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<ul class="navbar-nav ms-auto">
						<!-- <li class="nav-item me-2 mb-2">
							<router-link class="btn btn-outline-light" to="/swap">Swap DCT OLD to DCT NEW</router-link>
						</li>
						<li v-if="!isTronLinkActive" class="nav-item me-2 mb-2">
							<button class="btn btn-outline-light" :disabled="adapter.connected" @click="adapter.connect()">
								Connect to TronLink
							</button>
						</li>
						<li v-if="isTronLinkActive" class="nav-item dropdown">
							<a class="dropdown-toggle nav-link" aria-expanded="false" data-bs-toggle="dropdown" data-bs-auto-close="outside" href="#"><img src="assets/img/logo-img.png" />{{ obfuscateString(account, 4, 4) }}</a>
							<div class="dropdown-menu dropdown-menu-end" v-if="user!=null">
								<a @click.prevent="logout" class="dropdown-item">Logout</a>
							</div>
						</li> -->
					</ul>
				</div>
			</div>
		</nav>
		<!-- <div class="container-fluid p-3 p-md-4" v-if="isTronLinkActive">
			<div class="row gap-md-0 gap-3">
				<div class="col-12">
					<div class="main-content">
						<div class="main-content_title">
							<h4>Staking History</h4>
						</div>
						<div class="main-content_body table-responsive ps-3 pe-3" v-if="stakinghistories.length > 0">
							<EasyDataTable
								show-index
								:headers="headers"
								:items="items"
								@click-row="showRow"
							/>
						</div>
						<div v-if="stakinghistories.length <= 0" class="main-content_body d-flex flex-grow-1 align-items-center justify-content-center">
							<div class="nodata p-4">
								<img src="assets/img/figure-nopayment.png" />
								<p class="lead fw-bold mb-1">No Data Yet!</p>
								<p class="small">Aww, its seems you still not find your favorite content yet. Let’s get one!
								</p>
								<a class="btn btn-primary" role="button" href="#">Explore Content</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<div class="container-fluid p-3 p-md-1" v-if="isTronLinkActive">
			<div class="row gap-md-0 gap-3">
				<div class="d-grid gap-2 col-lg-8 col-md-10 col-10 mx-auto">
					<div class="main-content">
						<div class="main-content_title text-center">
							<p>{{ account }}</p>
						</div>
						<div class="main-content_body ps-3 pe-3" v-if="items.length > 0">
							<!-- <EasyDataTable
								show-index
								:headers="headers"
								:items="items"
								@click-row="showRow"
							/> -->
							<div class="table-responsive">
								<table class="table">
									<thead>
										<tr>
											<th>HASH</th>
											<th>ADDRESS</th>
											<th>AMOUNT</th>
											<th>CREATED</th>
										</tr>
									</thead>
									<tbody v-if="items.length > 0">
										<tr v-for="item in items" :key="item.tx_id" @click="showRow(item)">
											<td>{{ item.tx_id }}</td>
											<td>{{ item.address }}</td>
											<td>{{ item.amount }}</td>
											<td>{{ item.date }}</td>
										</tr>
									</tbody>
									<tbody v-if="items.length <= 0">
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
									</tbody>
								</table>
							</div>
							
						</div>
						<div v-if="items.length <= 0" class="main-content_body d-flex flex-grow-1 align-items-center justify-content-center">
							<div class="nodata p-4">
								<img src="assets/img/figure-nopayment.png" />
								<p class="lead fw-bold mb-1">No Data Yet!</p>
								<p class="small">Aww, its seems you still not find your favorite content yet. Let’s get one!
								</p>
								<button class="btn btn-outline-light" role="button" @click="loadWaitingData()">
									Reload Data
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row" v-if="!isTronLinkActive">
			<div class="d-grid gap-2 col-6 mx-auto">
				<div class="main-content">
					<div class="main-content_title text-center">
						<h4>Tronlink Not Connected</h4>
					</div>
					<div class="main-content_body d-flex flex-grow-1 align-items-center justify-content-center">
						<div class="nodata p-4">
							<img src="assets/img/figure-nopayment.png" />
							<button class="btn btn-outline-light" role="button" :disabled="adapter.connected" @click="adapter.connect()">
								Connect to TronLink
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<modal-component :modelValue="showModalApprove" title="Approve Confirmation">
		<!-- Modal Content -->
		<div class="modal-body">
			<div class="mb-3">
				<strong>Are you sure approve this transfer?</strong>
				<ul>
					<li>Code : {{ activeRow.tx_id }}</li>
					<li>Recipient : {{ activeRow.address }}</li>
					<li>Amount : {{ activeRow.amount }}</li>
				</ul>
			</div>
		</div>
		<!-- Modal Footer -->
		<template #footer>
		<button class="btn btn-secondary" @click="showModalApprove = false">Close</button>
		<button type="button" class="btn btn-primary" @click="doApprove(activeRow)">Approve Now</button>
		</template>
	</modal-component>
</template>

<script>
// @ is an alias to /src
import { TronLinkAdapter } from '@tronweb3/tronwallet-adapters';
import moment from 'moment-timezone'
import { Toast } from 'bootstrap/dist/js/bootstrap.esm.min.js'
// import TronWeb from 'tronweb'
import axios from 'axios'

import ModalComponent from '../components/Modal.vue'
import ProgressBar from '../components/ProgressBar.vue'
// import { Header, Item, ClickRowArgument } from "vue3-easy-data-table";

const WalletReadyState = {
	NotFound: 'Not Found',
	Connecting: 'Connecting',
	Connected: 'Connected',
};

const setdecimal = 1000000000000000000;

export default {
	name: 'Home',
	components: {
		ModalComponent,
		ProgressBar,
	},
	data() {
		return {
			readyState: WalletReadyState.NotFound,
			account: '',
			network: {},
			signedMessage: '',
			adapter: new TronLinkAdapter(),
			loading: false,
			tronWeb: null,
			isTronLinkActive: false,
			balance: 0,
			token: null,
			scapproval: null,
			tokeninfo: {
				name: null,
				decimals: null,
				symbol: null,
			},
			toast: {},
			activeRow: {},
			waitingdata: [],
			headers: [],
			items: [],
			showModalApprove: false,
			adminaddr: ''
		}
	},
	async mounted() {
		// if (!localStorage.getItem('token')) {
		// 	this.$router.push('/login');
		// } else {
			// console.log('token information', this.tokeninfo);
			this.adminaddr = process.env.VUE_APP_ADMIN
			this.account = this.adapter.address;
			console.log(this.account)
			this.readyState = this.adapter.state;
			if (this.readyState == 'Connected') {
				// console.log('ready state')
				// this.isTronLinkActive = true;
				await this.getTronweb()
				// console.log('isTronLinkActive', this.isTronLinkActive)
				if (this.isTronLinkActive) {
					await this.refreshAllData()
				}
			} else {
				this.isTronLinkActive = false;
			}
			this.adapter.on('connect', async () => {
				// console.log('connect')
				// this.isTronLinkActive = true;
				await this.getTronweb()
				// console.log('isTronLinkActive', this.isTronLinkActive)
				if (this.isTronLinkActive) {
					await this.refreshAllData()
				}
			});
			this.adapter.on('readyStateChanged', async (newState) => {
				// console.log('readyStateChanged')
				this.readyState = newState;
				// console.log('state.readyState: ', this.readyState)
				await this.getTronweb()
				// console.log('isTronLinkActive', this.isTronLinkActive)
				if (this.isTronLinkActive) {
					await this.refreshAllData()
				}
			});
			this.adapter.on('accountsChanged', async (data) => {
				// console.log('accountsChanged')
				this.account = data;
				// console.log('change state.account: ', this.account)
				await this.getTronweb()
				// console.log('isTronLinkActive', this.isTronLinkActive)
				if (this.isTronLinkActive) {
					await this.refreshAllData()
				}
			});
			this.adapter.on('chainChanged', async (data) => {
				// console.log('chainChanged')
				this.network = data;
				// console.log('chainChanged', this.network)
				await this.getTronweb()
				// console.log('isTronLinkActive', this.isTronLinkActive)
				if (this.isTronLinkActive) {
					await this.refreshAllData()
				}
			});
			this.adapter.on('disconnect', () => {
				// when disconnect from wallet
			});
		// }
	},
	methods: {
		async refreshAllData() {
			if(this.account!=this.adminaddr) {
				this.isTronLinkActive = false;
			}
			if (this.isTronLinkActive) {
				await this.getToken()
				await this.getSC()
				await this.getWaitingData()
				// await this.getTokenInfo()
				// await this.getAccountInfo()
				// await this.getSwap()
			}
		},
		async getTronweb() {
				if (window.tronLink.ready) {
					this.tronWeb = tronLink.tronWeb;
					this.isTronLinkActive = true
				} else {
					const res = await tronLink.request({ method: 'tron_requestAccounts' });
					if (res.code === 200) {
						this.tronWeb = tronLink.tronWeb;
						this.isTronLinkActive = true
					}
				}
		},
		async getToken() {
			try {
				this.token = await this.tronWeb.contract().at(process.env.VUE_APP_TOKENADDR)
				// console.log('token : ', this.token)
			} catch (error) {
				console.log('error catch', error)
			}
		},
		async getSC() {
			try {
				this.scapproval = await this.tronWeb.contract().at(process.env.VUE_APP_APPROVAL)
				// console.log('scapproval : ', this.scapproval)
			} catch (error) {
				console.log('error catch', error)
			}
		},
		async loadWaitingData() {
			this.loading = true;
			await this.sleep(3000).then(async () => {
				await this.getWaitingData()
			})
			this.loading = false;
		},
		async getWaitingData() {
			// this.loading = true;
			try {
				this.items = [];
				let getdata = await axios.get('https://digitalscommunity.com/tools/listtransactionspending');
				// console.log('getdata : ', getdata.data)

				this.headers = [
					{ text: "HASH", value: "tx_id" },
					{ text: "ADDRESS", value: "address" },
					{ text: "AMOUNT", value: "amount" },
					{ text: "CREATED", value: "date", sortable: true },
				];
				this.waitingdata = getdata.data;
				var gt = this.waitingdata;
				
				for (let i = 0; i < gt.length; i++) {
					this.items[i] = {
						no: i + 1,
						tx_idx: gt[i].tx_id,
						tx_id: this.get32char(gt[i].tx_id),
						address: gt[i].address,
						amount: parseFloat(gt[i].amount),
						date: this.fromDate(gt[i].date),
					}
				}
				// this.items = this.waitingdata;
				// console.log('waiting data', this.waitingdata)
				// console.log('length: ' + this.waitingdata.length)
				
			} catch (error) {
				console.log(' error catch', error)
				// }
			}
		},
		showRow(item) {
			this.activeRow = item;
			this.openModalApprove()
			// console.log('item', item)
			// window.open(baseurl + item.txid, '_blank');
		},
		async doApprove(item) {
			// console.log('do Stake')
			var tx_id = tronWeb.fromUtf8(item.tx_id);
			this.loading = true;
			try {
				let isApprove = await this.scapproval.approveTransfer(tx_id).send({
					feeLimit: 100000000,
				})
				await setTimeout(async () => {
					let txStatus = await this.tronWeb.trx.getTransaction(isApprove);
					let sts = txStatus.ret[0].contractRet;
					if (sts == 'SUCCESS') {
						this.showToastSuccess('Approve success')
						// post status to api
						await axios.post('https://digitalscommunity.com/tools/updateapprovewd', {
							token: 'TVzmao9LvXMmvW2opQaB6yXbpEdqjNUEty',
							tx_id: item.tx_idx,
							trx_hash: isApprove
						}, {
							headers: {
								"Content-Type": "multipart/form-data"
							}
						});

						await this.sleep(3000).then(async () => {
							await this.getWaitingData()
						})
						
						this.hideModalApprove()
						this.loading = false;
						// window.location.reload()
					} else {
						this.showToastError('Approve failed')
						this.loading = false;
					}
				}, 5000)
			} catch (error) {
				this.loading = false;
				console.log('error catch', error)
			}
		},
		async openModalApprove() {
			this.loading = true;
			this.showModalApprove = true
			this.loading = false;
			
		},
		async sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms))
		},
		hideModalApprove() {
			this.showModalApprove = false
		},
		get32char(str) {
			return str.slice(0, 32);
		}, 
		obfuscateString(str, first, last) {
			// Ambil karakter pertama dan terakhir dari string
			const firstChars = str.slice(0, first);
			const lastChars = str.slice(-last);
			// Buat string tengah dengan karakter titik-titik
			const middleChars = '...';
			// Gabungkan semua string dan kembalikan hasilnya
			return firstChars + middleChars + lastChars;
		},
		showToastSuccess(text) {
			this.toast.body = text
			const toastLiveExample = document.getElementById('toastSuccess')
			var toast = new Toast(toastLiveExample)
			toast.show()
		},
		showToastError(text) {
			this.toast.body = text
			const toastLiveExample = document.getElementById('toastError')
			var toast = new Toast(toastLiveExample)
			toast.show()
		},
		fromDate(date) {
			moment.tz.setDefault("Asia/Jakarta");
			// var localdate = moment(date).tz('Asia/Jakarta').format();
			return moment(date).fromNow()
		},
		setDeadlineTime(date) {
			let localTZ = moment.tz.guess();
			moment.tz.setDefault(localTZ);
			// var localdate = moment(date).tz('Asia/Jakarta').format();
			return moment.unix(date).format()
		},
		fromDateTime(date) {
			moment.tz.setDefault("Asia/Jakarta");
			// var localdate = moment(date).tz('Asia/Jakarta').format();
			return moment.unix(date).fromNow()
		},
		fromSun(amount) {
			var amn = BigInt(amount)
			// console.log(amn)
			var calcAmount = Number(amn * 100000000n / BigInt(setdecimal)) / 100000000;
			// console.log(calcAmount)
			return calcAmount.toString();;
		},
		toSun(amount) {
			var calcAmount = BigInt(amount * setdecimal);
			return calcAmount.toString();;
		}
	}
}
</script>
