// import Home from '../views/Home.vue'
// import Login from '../views/Login.vue'
// import Register from '../views/Register.vue'
// import Resetpassword from '../views/Resetpassword.vue'
import Approval from '../views/Approval.vue'
// import Stakinghistories from '../views/Stakinghistories.vue'
// import Claimhistories from '../views/Claimhistories.vue'
import NProgress from 'nprogress';
import { createRouter, createWebHashHistory } from 'vue-router'

const history = createWebHashHistory()
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Approval,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/approval',
    name: 'Approval',
    component: Approval,
    meta: {
      layout: 'default'
    }
  },
]
const router = createRouter({
  linkActiveClass: 'active',
  history,
  routes
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
      // Start the route progress bar.
      NProgress.start()
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export { router }
